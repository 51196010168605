import React from "react"
import { Article, Paragraph, FanningTriangles, Heading } from "../components"

const Contact: React.FC = () => {
  /**
   * Contact page with JavaScript shennanigans to fool email harvesters
   */
  const p1 = "therealcharlesdesbiens"
  const p2 = Math.pow(2, 6)
  const p3 = String.fromCharCode(p2)
  const p4 = "gmail.com"
  const p5 = p1 + p3 + p4
  return (
    <Article>
      <FanningTriangles />
      <Heading>Contact</Heading>
      <Paragraph>
        I am currently available for part-time or full-time contracts. Remote
        work is preferred, but I would be willing to relocate to select cities
        in Canada or the U.S.
      </Paragraph>
      <Paragraph>
        You can reach me by email at:{" "}
        <a href={`mai${"lto:"}${p5}`} className="text-cyan">
          {p5}
        </a>
      </Paragraph>
    </Article>
  )
}

export default Contact
